import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import {
  useDetailAccountList,
  useLinkedBankAccountData,
} from "../hooks/account.hooks";
import { getAccountCategoryTitle } from "../utils/finance-utils";
import AddAccountButton from "./add-account-button";
import LinkedAccount from "./linked_account";

const LinkedAccountCarousel = ({
  filteredCategories = [],
  selectedAccountId,
  onSelectedAccountIdChange,
  renderAccountCombinedItem,
}) => {
  const { isLoading, isIdle } = useDetailAccountList();
  const linkedBankAccountData = useLinkedBankAccountData();
  const isDataLoaded = !(isLoading || isIdle);

  return (
    <ScrollContainer horizontal className="scroll-container">
      {!linkedBankAccountData && (
        <div className="linked-account-section">
          <div className="linked-account-header">Linked Accounts</div>
          <div className="d-flex">
            {!isDataLoaded && (
              <LinkedAccount logo="https://d388vpyfrt4zrj.cloudfront.net/AU00000.svg" />
            )}
            <AddAccountButton />
          </div>
        </div>
      )}
      {isDataLoaded &&
        filteredCategories.map((category) => {
          if (linkedBankAccountData?.[category].length > 0) {
            return (
              <div key={category} className="linked-account-section">
                <div className="linked-account-header">
                  {getAccountCategoryTitle(category)}
                </div>
                <div className="d-flex">
                  {renderAccountCombinedItem?.(
                    category,
                    linkedBankAccountData[category]
                  )}
                  {linkedBankAccountData[category].map(
                    ({
                      accountId,
                      institutionLogo,
                      name,
                      balance,
                      ownerName,
                    }) => (
                      <LinkedAccount
                        key={accountId}
                        cursor
                        accountId={accountId}
                        logo={institutionLogo}
                        name={name}
                        value={balance}
                        ownerName={ownerName}
                        isSelected={accountId === selectedAccountId}
                        onClick={onSelectedAccountIdChange}
                      />
                    )
                  )}
                  <AddAccountButton />
                </div>
              </div>
            );
          }
          return undefined;
        })}
    </ScrollContainer>
  );
};

LinkedAccountCarousel.propTypes = {};

export default LinkedAccountCarousel;
