import React from "react";
import { useLinkedBankAccountData } from "../hooks/account.hooks";
import { getAccountCategoryTitle } from "../utils/finance-utils";
import { formatNumberWithDollar } from "../utils/numberFormater";
import AddAccountButton from "./add-account-button";
import LinkedAccount from "./linked_account";

const LinkedBank = ({
  filteredCategories = [],
  title: headerTitle = "",
  value,
  cursor,
  selectedAccountId,
  onSelectedAccountIdChange,
  renderAccountCombinedItem,
}) => {
  const linkedBankAccountData = useLinkedBankAccountData() || {};
  const isDataLoaded = Object.keys(linkedBankAccountData).length !== 0;

  return (
    <div className="da-body-linked-account">
      <div className="linked-bank">
        <div className="linked-account-header la-container">
          <div className="">
            <div className="header-title">{headerTitle}</div>
            <div className="header-value">
              {typeof value === "number"
                ? formatNumberWithDollar(value)
                : value}
            </div>
          </div>
        </div>
        {isDataLoaded &&
          filteredCategories.map((category) => {
            if (linkedBankAccountData[category].length > 0) {
              return (
                <div key={category} className="linked-account-section">
                  {getAccountCategoryTitle(category) && (
                    <div className="linked-account-header la-container">
                      {getAccountCategoryTitle(category)}
                    </div>
                  )}
                  {isDataLoaded &&
                    renderAccountCombinedItem?.(
                      category,
                      linkedBankAccountData[category]
                    )}
                  {linkedBankAccountData[category].map((account) => {
                    const {
                      accountId = "",
                      institutionLogo = "",
                      name = "",
                      balance = 0,
                      ownerName,
                    } = account;
                    return (
                      <LinkedAccount
                        key={accountId}
                        accountId={accountId}
                        logo={institutionLogo}
                        name={name}
                        value={balance}
                        ownerName={ownerName}
                        cursor={cursor}
                        isSelected={accountId === selectedAccountId}
                        onClick={onSelectedAccountIdChange}
                      />
                    );
                  })}
                  <AddAccountButton />
                </div>
              );
            }
            return undefined;
          })}
      </div>
    </div>
  );
};

export default LinkedBank;

LinkedBank.propTypes = {};
